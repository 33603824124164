import React, { useState } from "react";
import { Button, Typography, Row, Col, Alert, Steps } from "antd";
import { useAccount } from "@vechain.energy/use-vechain";
import TokenList from "./TokenList";
import Transfer from "./Transfer";
import SponsoredBy from "./SponsoredBy";

const { Text, Link } = Typography;
const { Step } = Steps;

export default function WalletWiper() {
  const { account, isLoading, error, connect, disconnect } = useAccount();
  const [tokensToTransfer, setTokensToTransfer] = useState([]);
  const [success, setSuccess] = useState(false);
  const handleSuccess = () => setSuccess(true);
  const handleReset = () => setSuccess(false);

  const handleConnect = () =>
    connect(
      "sign a certificate to share your public wallet address and read your wallet balance"
    );

  let step = 0;
  if (account) {
    step++;
  }

  if (tokensToTransfer.length > 0) {
    step++;
  }

  if (step === 2 && success) {
    step++;
  }

  const Identify = () => {
    if (!account) {
      return (
        <Button
          onClick={handleConnect}
          loading={isLoading}
          type="primary"
          shape="round"
          size="large"
          style={{ marginTop: 20, marginBottom: 20 }}
          block
        >
          identify to read wallet balance
        </Button>
      );
    }
    return (
      <Text type="secondary">
        cleaning your account {account}{" "}
        <Link onClick={disconnect}>(sign out)</Link>
      </Text>
    );
  };

  return (
    <Row gutter={[16, 64]} style={{ maxWidth: 768, margin: "auto" }}>
      <Col span={22} offset={1}></Col>
      <Col span={22} offset={1}>
        {!!error && <Alert message={error} type="error" closable></Alert>}

        <Steps direction="vertical" size="large" current={step}>
          <Step title="Identify" description={<Identify />} />
          <Step
            title="Select Tokens"
            description={
              <TokenList
                account={account}
                onChange={setTokensToTransfer}
                style={{ marginBottom: 20 }}
              />
            }
          />
          <Step
            title="Empty Wallet"
            description={
              <Transfer
                tokens={tokensToTransfer}
                onSuccess={handleSuccess}
                onReset={handleReset}
              />
            }
          />
        </Steps>
      </Col>

      <Col span={22} offset={1} align="center">
        <SponsoredBy />
      </Col>
    </Row>
  );
}
