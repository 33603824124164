import { useEffect, useState, useCallback } from "react";
import {
  useBalance,
  useTokens,
  useContracts
} from "@vechain.energy/use-vechain";

const AbiBalanceOf = {
  constant: true,
  inputs: [{ name: "_owner", type: "address" }],
  name: "balanceOf",
  outputs: [{ name: "balance", type: "uint256" }],
  payable: false,
  stateMutability: "view",
  type: "function"
};
const AbiTransfer = {
  constant: false,
  inputs: [
    { name: "_to", type: "address" },
    { name: "_value", type: "uint256" }
  ],
  name: "transfer",
  outputs: [{ name: "", type: "bool" }],
  payable: false,
  stateMutability: "nonpayable",
  type: "function"
};
const AbiSymbol = {
  constant: true,
  inputs: [],
  name: "symbol",
  outputs: [{ name: "symbol", type: "string" }],
  payable: false,
  stateMutability: "view",
  type: "function"
};
const Abis = [AbiTransfer, AbiBalanceOf, AbiSymbol];

export default function useTokenList(account) {
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState([]);
  const [empty, setEmpty] = useState([]);
  const { tokens } = useTokens();

  // get a list of interactionable contract objects with the token addresses and the given Abi
  const tokenContracts = useContracts(
    tokens.map(({ address }) => address),
    Abis
  );
  const { balanceOf } = useBalance();

  const updateBalance = useCallback(async () => {
    if (!account) {
      return;
    }
    setLoading(true);
    const balance = [];
    const empty = [];
    const { vet } = await balanceOf(account, true);

    // VET balance
    if (vet !== '0x0') {
      balance.push({
        amount: vet,
        symbol: "VET"
      });
    }

    // loop thru all known tokens from token-registry
    for (const tokenContract of tokenContracts) {
      // get current balance
      const { balance: amount } = await tokenContract.balanceOf(account);
      // read symbol for user friendliness in comments
      const { symbol } = await tokenContract.symbol();

      if (amount > 0) {
        balance.push({
          address: tokens.find((token) => token.symbol === symbol).address,
          contract: tokenContract,
          amount,
          symbol,
          icon: tokens.find((token) => token.symbol === symbol).icon
        });
      } else {
        empty.push({
          amount,
          symbol,
          icon: tokens.find((token) => token.symbol === symbol).icon
        });
      }
    }

    setBalance(balance);
    setEmpty(empty);
    setLoading(false);
  }, [account, balanceOf, tokenContracts]);

  useEffect(() => {
    updateBalance();
  }, [updateBalance]);

  return { loading, tokens: balance, empty, refresh: updateBalance };
}
