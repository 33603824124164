import React, { useEffect, useState } from "react";
import { Button, Typography, Row, Col, Switch, Avatar, Image } from "antd";
import { ethers } from "@vechain/ethers";
import { ReloadOutlined as IconReload, PlusOutlined as IconSelectAll, MinusOutlined as IconDeSelectAll } from "@ant-design/icons";
import useTokenList from "./useTokenList";

const { Text } = Typography;

export default function TokenList({ account, onChange: handleChange, style }) {
  const { tokens, empty, refresh, loading } = useTokenList(account);
  const [tokensToTransfer, setTokensToTransfer] = useState([...tokens]);
  const [displayZero, setDisplayZero] = useState(false);

  const toggleZero = () => setDisplayZero(!displayZero);


  const handleSelectAll = () => setTokensToTransfer(tokens)
  const handleDeSelectAll = () => setTokensToTransfer([])

  const toggleTokenTransfer = (token) => () => {
    if (tokensToTransfer.find((_token) => _token.symbol === token.symbol)) {
      return setTokensToTransfer(
        tokensToTransfer.filter((_token) => _token.symbol !== token.symbol)
      );
    }
    setTokensToTransfer([...tokensToTransfer, token]);
  };
  useEffect(() => {
    handleChange(tokensToTransfer);
  }, [handleChange, tokensToTransfer]);

  if (!account) {
    return (
      <Text type="secondary">
        select tokens to transfer to new wallet
      </Text>
    );
  }

  return (
    <Row gutter={[16, 16]} style={style}>
      <Col span={24} align="right">
        <Button
          loading={loading}
          onClick={refresh}
          icon={<IconReload />}
          size="small"
          type="link"
        >
          refresh balance
        </Button>
      </Col>
      {tokens.map((token) => (
        <React.Fragment key={token.symbol}>
          <Col span={4}>
            <Text type="secondary">
              <Avatar
                size={32}
                src={
                  !token.icon ? null : (
                    <Image
                      src={token.icon}
                      style={{ width: 32 }}
                      preview={false}
                    />
                  )
                }
              >
                {token.symbol}
              </Avatar>
            </Text>
          </Col>

          <Col span={10} align='right'>
              <Text type="secondary" style={{ fontSize: "0.75rem" }}>
                {ethers.utils.formatEther(token.amount)}
              </Text>
            </Col>
            <Col span={4}>
              <Text type="secondary" style={{ fontSize: "0.75rem" }}>
                {token.symbol}
              </Text>
            </Col>
          <Col span={6} align="right">
            <Switch
              checkedChildren={`transfer all`}
              unCheckedChildren="leave in wallet"
              checked={tokensToTransfer.includes(token)}
              onChange={toggleTokenTransfer(token)}
            />
          </Col>
        </React.Fragment>
      ))}

      <Col span={24} align='right'>
        {tokens.length > 0 && tokensToTransfer.length === 0 && <Button type='link' size='small' icon={<IconSelectAll />} onClick={handleSelectAll}>select all</Button>}
        {tokensToTransfer.length === tokens.length && <Button type='link' size='small' icon={<IconDeSelectAll />} onClick={handleDeSelectAll}>de-select all</Button>}
      </Col>

      <Col span={24} align="center">
        <Button
          onClick={toggleZero}
          size="small"
          type="link"
          style={{ opacity: 0.5 }}
        >
          {!displayZero ? (
            <>show {empty.length} zero balances</>
          ) : (
            <>hide {empty.length} zero balances</>
          )}
        </Button>
      </Col>
      {displayZero &&
        empty.map((token) => (
          <React.Fragment key={token.symbol}>
            <Col span={4}>
              <Text type="secondary">
                <Avatar
                  size={32}
                  src={
                    !token.icon ? null : (
                      <Image
                        src={token.icon}
                        style={{ width: 32 }}
                        preview={false}
                      />
                    )
                  }
                >
                  {token.symbol}
                </Avatar>
              </Text>
            </Col>

            <Col span={10} align='right'>
              <Text type="secondary" style={{ fontSize: "0.75rem" }}>
                {ethers.utils.formatEther(token.amount)}
              </Text>
            </Col>
            <Col span={4}>
              <Text type="secondary" style={{ fontSize: "0.75rem" }}>
                {token.symbol}
              </Text>
            </Col>
            <Col span={6} align="right">
              <Switch
                disabled
                checkedChildren={`transfer all`}
                unCheckedChildren="leave in wallet"
                checked={tokensToTransfer.includes(token)}
                onChange={toggleTokenTransfer(token)}
              />
            </Col>
          </React.Fragment>
        ))}
    </Row>
  );
}
