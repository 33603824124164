import "antd/dist/antd.css";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { VeChainProvider } from "@vechain.energy/use-vechain";

import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <VeChainProvider
      config={{
        /* configure main net */
        node: "https://mainnet.veblocks.net",
        network: "main"
      }}
      options={{
        /* configure fee delegation */
        delegate: "https://sponsor.vechain.energy/by/29",

        /* test delegation to display user errors before the wallet does */
        delegateTest: "https://sponsor.vechain.energy/by/29/test"
      }}
    >
      <App />
    </VeChainProvider>
  </StrictMode>
);
