import React, { useState } from "react";
import {
  Button,
  Input,
  Typography,
  Alert,
  Avatar,
  Image,
  Row,
  Col,
  Grid
} from "antd";
import {
  useAccount,
  useMultiTask,
  useSendTransaction
} from "@vechain.energy/use-vechain";

const { Text, Link } = Typography;
const { useBreakpoint } = Grid

export default function WalletWiper({ tokens, onSuccess, onReset }) {
  const { account } = useAccount();
  const { multiTask } = useMultiTask();
  const [recipient, setRecipient] = useState();
  const [receipt, setReceipt] = useState();
  const [wipeError, setWipeError] = useState();
  const [loading, setLoading] = useState(false);
  const { sendTransaction } = useSendTransaction();
  const screens = useBreakpoint()

  const resetReceipt = () => {
    setReceipt();
    onReset();
  };

  const handleWipe = async () => {
    setLoading(true);
    setWipeError();
    try {
      if (!recipient) {
        throw new Error("enter new wallets address");
      }
      // initiate multi task transaction
      const tx = await multiTask(
        async (transaction) => {
          // loop thru all known tokens from token-registry
          for (const { contract, amount, symbol } of tokens) {
            if (!contract) {
              sendTransaction(
                { to: recipient, value: amount },
                {
                  comment: `wipe balance of ${symbol}`,
                  transaction
                }
              );
              continue;
            }

            // get current balance
            const { balance } = await contract.balanceOf(account);

            if (balance > 0) {
              // add transfer of token to new wallet
              contract.transfer(recipient, balance, {
                comment: `wipe balance of ${symbol}`,
                transaction
              });
            }
          }

          // transaction will auto commit, Sync2 will require user to confirm
        },
        { comment: "wipe wallet" }
      );


      const receipt = await tx.getReceipt();
      setReceipt(receipt);
      onSuccess();
    } catch (err) {
      setWipeError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (!account) {
    return <Text type="secondary">send all tokens to new wallet, leave no dust behind</Text>;
  }

  return (
    <>
      <Text type="secondary">
        enter the new wallet address to send the tokens to
      </Text>
      <Input
        placeholder="0x…"
        onChange={(e) => setRecipient(e.target.value)}
        value={recipient}
      />

      <Button
        onClick={handleWipe}
        loading={loading}
        type="primary"
        size="large"
        style={{ marginTop: 20 }}
        block
        shape="round"
        disabled={!!receipt || tokens.length === 0}
      >
        {(loading || !screens.md) && <>start transfer for {tokens.length} tokens</>}
        {(!loading && screens.md) && (
          <Row align="center" gutter={[16, 16]}>
            <Col flex>
              <Avatar.Group size={24}>
                {tokens.map((token) => (
                  <Avatar
                    key={token.symbol}
                    src={
                      !token.icon ? null : (
                        <Image
                          src={token.icon}
                          style={{ width: 16, margin: 4 }}
                          preview={false}
                        />
                      )
                    }
                    style={{
                      backgroundColor: "#eee",
                      borderColor: "#999",
                      color: "#333"
                    }}
                  >
                    {token.symbol}
                  </Avatar>
                ))}
              </Avatar.Group>
            </Col>
            <Col flex>start transfer for {tokens.length} tokens</Col>
          </Row>
        )}
      </Button>
      <br />
      <br />
      {!!wipeError && <Alert message={wipeError} type="error" closable></Alert>}

      {!!receipt && (
        <Alert
          message="Transfer completed"
          description={
            <Link
              href={`https://explore.vechain.org/transactions/${receipt.meta.txID}`}
              rel="noreferrer"
              target="_blank"
            >
              view your transaction id:
              <br />
              {receipt.meta.txID}
            </Link>
          }
          type="success"
          onClose={resetReceipt}
          closable
        ></Alert>
      )}
    </>
  );
}
